import React, { useEffect, useMemo } from 'react';
import { useHierarchicalMenu } from 'react-instantsearch';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import Checkmark from '../../images/checkmark-w.svg';
import Translate from '../Translate/Translate';
import { Helmet } from 'react-helmet';

const GET_CATEGORIES_LIST = gql`
  query GetCategoriesList {
    categoriesList {
      id
      slug
      title
      longTitle
      subCategories {
        longTitle
      }
    }
  }
`;

interface AlgoliaFacetHierarchicalProps {
  facetName: string;
}

const AlgoliaFacetHierarchical = ({
  facetName,
}: AlgoliaFacetHierarchicalProps) => {
  const location = useLocation();
  const { data: allCategories } = useQuery(GET_CATEGORIES_LIST);

  const { items, refine, canRefine } = useHierarchicalMenu({
    limit: 20,
    attributes: [
      `${facetName}.lvl0`,
      `${facetName}.lvl1`,
      `${facetName}.lvl2`,
      `${facetName}.lvl3`,
    ],
  });

  const currentPath = useMemo(() => {
    let currentLevel = items.find((item) => item.isRefined);
    let finalPath = '';
    while (currentLevel) {
      finalPath = currentLevel.value;
      currentLevel = currentLevel.data?.find((item) => item.isRefined);
    }

    return finalPath;
  }, [items]);

  useEffect(() => {
    const categories = location.pathname
      .split('/')
      .filter((slug) => !['tooted', 'ru', 'lv', 'et', ''].includes(slug));
    if (categories.length && canRefine) {
      // Category path was changed from main menu.
      const targetPath = categories.join(' > ');
      if (targetPath !== currentPath) {
        refine(targetPath);
      }
    }
  }, [location, canRefine]);

  const getLabel = (slug) => {
    if (!allCategories) {
      return slug;
    }

    const category = allCategories.categoriesList.find(
      (category) => category.slug === slug,
    );
    return category?.title || slug;
  };

  const getLongTitle = (path) => {
    if (!allCategories) {
      return path;
    }

    const slugs = path.split(' > ');
    const titles = slugs.map((slug) => {
      const category = allCategories.categoriesList.find(
        (category) => category.slug === slug,
      );
      return category?.longTitle || slug;
    });

    return titles.join(' | ');
  };

  const isItemEnabled = (slug) => {
    if (!allCategories) {
      return false;
    }

    return allCategories.categoriesList.some(
      (category) => category.slug === slug,
    );
  };

  return (
    <div>
      <Helmet>
        {currentPath ? (
          <title>{getLongTitle(currentPath)} | Rademar e-pood </title>
        ) : null}
        <meta name="description" content={''} />
      </Helmet>
      <div className="flex lg:min-w-max lg:justify-between lg:items-center lg:pb-2 lg:border-b lg:border-rgrey-light lg:mt-5">
        <h4 className="h4 uppercase">
          {facetName === 'categories' && (
            <Translate component="facet-hierarchica" keyword="category" />
          )}
        </h4>
      </div>
      {/* {isActive && ( */}
      <div className="mt-6">
        {items.map((item) =>
          isItemEnabled(item.label) ? (
            <div className="" key={item.value}>
              <button
                className="w-full flex items-center mb-2"
                onClick={() => refine(item.value)}
              >
                <div
                  className={classNames(
                    'mx-2 w-7 h-7 border flex justify-center items-center border-rgrey-light rounded',
                    {
                      'bg-black border-black z-10': item.isRefined,
                    },
                  )}
                >
                  {item.isRefined && <img className="p-1" src={Checkmark} />}
                </div>
                <div className="w-full flex justify-between">
                  <p className="paragraph text-left ">
                    {getLabel(item.label)}{' '}
                  </p>
                  <p className="flex justify-center items-center  label font-axi-bold px-2  leading-6 text-rgrey rounded bg-rgrey-light">
                    {item.count}
                  </p>
                </div>
              </button>

              {item.data ? (
                <div className="mb-6 border-l border-rgrey-light h-fit ml-3 relative after:content-['..'] after:bg-white after:bottom-0 after:h-3 after:text-white after:absolute after:-ml-1">
                  {item.data.map((subItem) =>
                    isItemEnabled(subItem.label) ? (
                      <div key={subItem.value} className="mb-2 last:mb-0">
                        <button
                          key={subItem.value}
                          className="w-full flex items-center"
                          onClick={() => {
                            refine(subItem.value);
                          }}
                        >
                          <div className="w-3 border-b border-rgrey-light pr-2"></div>
                          <div
                            className={classNames(
                              'mr-3 w-7 h-7 border flex justify-center items-center border-rgrey-light rounded',
                              {
                                'bg-black border-black z-10': subItem.isRefined,
                              },
                            )}
                          >
                            {item.isRefined && (
                              <img className="p-1" src={Checkmark} />
                            )}
                          </div>
                          <div className="w-full flex justify-between">
                            <p className="paragraph text-left">
                              {getLabel(subItem.label)}{' '}
                            </p>
                            <p className="flex justify-center leading-none items-center  label font-axi-bold px-2 pt-0.5 text-rgrey rounded bg-rgrey-light">
                              {subItem.count}
                            </p>
                          </div>
                        </button>

                        {subItem.data ? (
                          <div className="mb-6 mt-2 border-l border-rgrey-light h-full ml-7 relative after:content-['..'] after:bg-white after:bottom-0 after:h-3 after:text-white after:absolute after:-ml-1">
                            {subItem.data.map((subItem2) =>
                              isItemEnabled(subItem2.label) ? (
                                <div
                                  key={subItem2.value}
                                  className="mb-2 last:mb-0"
                                >
                                  <button
                                    key={subItem2.value}
                                    className="w-full flex items-center"
                                    onClick={() => refine(subItem2.value)}
                                  >
                                    <div className="w-3 border-b border-rgrey-light mr-2"></div>
                                    <div
                                      className={classNames(
                                        'mr-3 w-7 h-7 border flex justify-center items-center border-rgrey-light rounded',
                                        {
                                          'bg-black border-black z-10':
                                            subItem2.isRefined,
                                        },
                                      )}
                                    >
                                      {item.isRefined && (
                                        <img className="px-1" src={Checkmark} />
                                      )}
                                    </div>
                                    <div className="w-full flex justify-between">
                                      <p className="paragraph text-left">
                                        {getLabel(subItem2.label)}{' '}
                                      </p>
                                      <p className="flex justify-center leading-none items-center  label font-axi-bold px-2 pt-0.5 text-rgrey rounded bg-rgrey-light">
                                        {subItem2.count}
                                      </p>
                                    </div>
                                  </button>

                                  {subItem2.data ? (
                                    <div className="mb-6 border-l border-rgrey-light h-full ml-7 relative after:content-['..'] after:bg-white after:bottom-0 after:h-3 after:text-white after:absolute after:-ml-1">
                                      {subItem2.data.map((subItem3) =>
                                        isItemEnabled(subItem3.label) ? (
                                          <div
                                            key={subItem3.value}
                                            className="mb-2 last:mb-0"
                                          >
                                            <button
                                              key={subItem3.value}
                                              className="w-full flex items-center mt-2"
                                              onClick={() =>
                                                refine(subItem3.value)
                                              }
                                            >
                                              <div className="w-3 border-b border-rgrey-light mr-2"></div>
                                              <div
                                                className={classNames(
                                                  'mr-3 w-7 h-7 border flex justify-center items-center border-rgrey-light rounded',
                                                  {
                                                    'bg-black border-black':
                                                      subItem3.isRefined,
                                                  },
                                                )}
                                              >
                                                {item.isRefined && (
                                                  <img
                                                    className="p-1"
                                                    src={Checkmark}
                                                  />
                                                )}
                                              </div>
                                              <div className="w-full flex justify-between">
                                                <p className="paragraph text-left">
                                                  {getLabel(
                                                    subItem3.label,
                                                  )}{' '}
                                                </p>
                                                <p className="flex justify-center leading-none items-center  label font-axi-bold px-2 pt-0.5 text-rgrey rounded bg-rgrey-light">
                                                  {subItem3.count}
                                                </p>
                                              </div>
                                            </button>
                                          </div>
                                        ) : null,
                                      )}
                                      {/* <div className="absolute border-l-4 border-white h-3 bottom-[1px] -ml-0.5"></div> */}
                                    </div>
                                  ) : null}
                                </div>
                              ) : null,
                            )}
                            {/* <div className="absolute border-l-4 border-white h-3 bottom-[1px] -ml-0.5"></div> */}
                          </div>
                        ) : null}
                      </div>
                    ) : null,
                  )}
                  {/* <div className="absolute border-l-4 border-white h-4 bottom-0.5 -ml-0.5"></div> */}
                </div>
              ) : null}
            </div>
          ) : null,
        )}
      </div>
      {/* )} */}
    </div>
  );
};

export default AlgoliaFacetHierarchical;
