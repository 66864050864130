import React, { useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Configure, useInstantSearch, useSearchBox } from 'react-instantsearch';
import { createInsightsMiddleware } from 'instantsearch.js/es/middlewares';
import aa from 'search-insights';
import { getPersonalisationKey } from '../../services/Personalisation';
import config from '../../services/Config';
import Products from './Products';

aa('init', {
  appId: config.algoliaAppId,
  apiKey: config.algoliaSearchKey,
  useCookie: false,
});

if (getPersonalisationKey()) {
  aa('setUserToken', getPersonalisationKey());
}

function InsightsMiddleware() {
  const { addMiddlewares } = useInstantSearch();

  useLayoutEffect(() => {
    const middleware = createInsightsMiddleware({ insightsClient: aa });

    return addMiddlewares(middleware);
  }, [addMiddlewares]);

  return null;
}

const OrderedCategory = () => {
  const { refine } = useSearchBox();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const searchQueryValue = queryParams.get('query');

  useEffect(() => {
    if (searchQueryValue) {
      refine(searchQueryValue);
    }
  }, [searchQueryValue]);

  return (
    <div className="flex">
      <InsightsMiddleware />
      <Configure ruleContexts={['category-page']} />
      <Products />
    </div>
  );
};

export default OrderedCategory;
